/* DatePicker wrapper styles */
.subscription-component .react-datepicker-wrapper {
  width: 100%;
  position: relative;
  z-index: 1;
}

/* DatePicker popper styles */
.subscription-component .react-datepicker-popper {
  z-index: 9999 !important;
}

/* Floating label styles */
.subscription-date-floating-label {
  position: relative;
  height: calc(3.5rem + 2px);
}

.subscription-date-floating-label .form-control {
  height: calc(3.5rem + 2px) !important;
  padding: 1.625rem 0.75rem 0.625rem !important;
  font-size: 1rem;
  line-height: 1.25;
}

.subscription-date-floating-label label {
  height: auto !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem;
  transform-origin: 0 0;
  background: transparent;
}

/* Main calendar styles */
.subscription-datepicker .react-datepicker {
  font-family: inherit;
  font-size: 0.9rem;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Calendar container styles */
.subscription-datepicker .react-datepicker__month-container {
  float: none;
  width: auto;
}

/* Calendar header styles */
.subscription-datepicker .react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #e9ecef;
  padding: 0.5rem 0;
}

.subscription-datepicker .react-datepicker__current-month {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.subscription-datepicker .react-datepicker__day-names {
  margin-bottom: 0.25rem;
}

/* Day name styles */
.subscription-datepicker .react-datepicker__day-name {
  color: #6c757d;
  width: 2rem;
  font-size: 0.8rem;
  margin: 0.1rem;
}

/* Day cell styles */
.subscription-datepicker .react-datepicker__day {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.1rem;
  font-size: 0.8rem;
  border-radius: 50%;
}

/* Special dates styling (28th-31st) */
.subscription-datepicker .react-datepicker__day[aria-label*="28"],
.subscription-datepicker .react-datepicker__day[aria-label*="29"],
.subscription-datepicker .react-datepicker__day[aria-label*="30"],
.subscription-datepicker .react-datepicker__day[aria-label*="31"] {
  color: #6c757d !important;
  background-color: #f8f9fa !important;
  cursor: pointer !important;
  opacity: 0.7 !important;
  text-decoration: line-through;
}

/* Disabled dates styles */
.subscription-datepicker .react-datepicker__day--disabled {
  color: #ccc !important;
  background-color: #f8f9fa !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

/* Selected date styles */
.subscription-datepicker .react-datepicker__day--keyboard-selected,
.subscription-datepicker .react-datepicker__day--selected {
  background-color: #0d6efd !important;
  color: white !important;
}

/* Hover styles for dates */
.subscription-datepicker
  .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  background-color: #e9ecef;
}

/* Navigation styles */
.subscription-datepicker .react-datepicker__navigation {
  top: 0.6rem;
}

.subscription-datepicker .react-datepicker__navigation-icon::before {
  border-color: #6c757d;
  border-width: 2px 2px 0 0;
  height: 8px;
  width: 8px;
}

/* Dropdown styles */
.subscription-datepicker .react-datepicker__month-select,
.subscription-datepicker .react-datepicker__year-select {
  font-size: 0.9rem;
  padding: 0.2rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .subscription-component .react-datepicker {
    width: 100%;
    font-size: 1.1rem;
  }

  .subscription-component .react-datepicker__month-container {
    width: 100%;
  }

  .subscription-component .react-datepicker__day,
  .subscription-component .react-datepicker__day-name {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    margin: 0.3rem;
    font-size: 1.1rem;
  }

  .subscription-component .react-datepicker__current-month {
    font-size: 1.3rem;
    padding: 0.5rem 0;
  }
}

/* Tooltip styles */
.subscription-component .custom-tooltip .tooltip-inner {
  background-color: #2c3e50;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  max-width: 300px;
  text-align: center;
  border-radius: 0.375rem;
  z-index: 10000;
}

.subscription-component .custom-tooltip .arrow::before {
  border-top-color: #2c3e50;
}

/* Safari-specific styles */
@supports (-webkit-touch-callout: none) {
  .subscription-component
    input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
  }
}

/* Form floating styles */
.subscription-component .form-floating {
  position: relative;
}

/* Subscription frequency text styles */
.subscription-frequency-text {
  padding: 0.5rem 0;
  color: #212529;
  font-size: 1rem;
}

.subscription-frequency-text strong {
  color: #0d6efd;
}

/* Override App.css styles with more specific selectors */
.subscription-component
  .form-floating
  > .form-control.subscription-date-input.form-control,
.subscription-component .form-floating > .form-select.form-select {
  height: calc(3.5rem + 2px) !important;
  line-height: 1.25;
  padding: 1.625rem 0.75rem 0.625rem !important;
  font-size: 1rem;
}

.subscription-component
  .form-floating
  .react-datepicker-wrapper
  input.form-control {
  height: calc(3.5rem + 2px) !important;
  padding: 1.625rem 0.75rem 0.625rem !important;
}

.subscription-component
  .form-floating
  > .form-control.subscription-date-input.form-control:focus,
.subscription-component
  .form-floating
  > .form-control.subscription-date-input.form-control:not(:placeholder-shown),
.subscription-component .form-floating > .form-select.form-select {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}

.subscription-component .form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;
  color: #6c757d;
}

/* Label transform styles */
.subscription-component
  .form-floating
  > .form-control.subscription-date-input:focus
  ~ label,
.subscription-component
  .form-floating
  > .form-control.subscription-date-input:not(:placeholder-shown)
  ~ label,
.subscription-component .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.subscription-component
  .form-floating
  > .form-control.subscription-date-input:-webkit-autofill
  ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
